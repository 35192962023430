import React, { useEffect, useRef, useState } from 'react';
import { FaInstagram } from 'react-icons/fa';
import { BrowserRouter as Router, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import './App.css';
import styled, { keyframes } from 'styled-components';
import GifPlayer from './+components/GifPlayer';
import Controls from './+components/Controls'
import gifPairs from './+assets/gifs.json';
import { ReactComponent as RmxLogo } from './+assets/rmx.lol_white.svg'
import { ReactComponent as DoubleTap } from './+assets/tap_hold.svg'
import { theme } from './theme'


const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
`;

const FooterOverlay = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 12px;
    padding: 5px 10px;
    border-top-left-radius: 5px;
    display: flex;
    align-items: center; // This aligns items vertically in the center
    justify-content: flex-end;

    @media (max-width: 768px) {  // Typical breakpoint for tablets and below
      width: 100%;
    }

    a {
        color: white; // This ensures the link inherits the text color
        text-decoration: none; // Removes the underline from the link
        margin: 0 5px;

        svg {
            margin: 0 5px;
        }
    }

    a:nth-of-type(2) {
        margin: 0 10px 0 0;

        svg {
            margin: 0 5px;
        }
    }

    svg {
        width: 20px; // Adjust based on the desired size
        height: 20px; // Adjust based on the desired size
    }
`;

const LogoWrapper = styled.div`
  display: inline-block;
  border-radius: 3px;
  &:hover {
        background-color: rgba(29, 255, 93, 0.8);
  }
`;

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<NavigateToFirst />} />
        <Route path="/:gifPairLabel" element={<GifViewer />} />
        <Route path="/player" element={<GifPlayer gifUrls={[gifPairs[6].gifUrl]} alphas={[1]} />} />
      </Routes>
    </Router>
  );
}


const NavigateToFirst: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Filter gifPairs to only include those with permission "public"
    const publicGifPairs = gifPairs.filter(gifPair => gifPair.permission === 'public');
    console.log(publicGifPairs)
    // Select a random gifPair from the filtered list
    const randomGifPair = publicGifPairs[Math.floor(Math.random() * publicGifPairs.length)];

    // Navigate to the selected gifPair's route using its label
    navigate(`/${randomGifPair.label}`);
  }, [navigate]);

  return null;
};

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 0.8; }
`;

const tapAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
`;

const TapInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${tapAnimation} 1s infinite, ${fadeIn} 20s forwards;
  transform-origin: center center;
  background-color: ${theme.primaryColor};
  color: ${theme.secondaryColor};
  font-weight: bold;
  border-radius: 50%;
`;

const TapTutorial = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  flex-direction: column;
`;

const TapAndHoldInner = styled(TapInner)`
  align-items: normal;
  g path {
    fill: ${theme.primaryColor};
  }
  svg {
    margin: 0.2em;
  }
  background-color: ${theme.highlightColor};
`;

const TapAndHoldLabel = styled.div`
  font-size: 10pt;
  font-weight: bold;
  margin-top: 0.5em;
  animation: ${tapAnimation} 1s infinite, ${fadeIn} 20s forwards;
`


const GifViewer: React.FC = () => {
  let { gifPairLabel } = useParams<{ gifPairLabel: string }>();
  let navigate = useNavigate();
  const [selectedGifPair, setSelectedGifPair] = useState<typeof gifPairs[0] | null>(null);
  const [alpha, setAlpha] = useState<number>(0.15);
  const [isRecording, setIsRecording] = useState(false);
  const isRecordingRef = useRef(isRecording);
  const accumulatedScrollDelta = useRef(0);
  const [autoDecreaseAlpha, setAutoDecreaseAlpha] = useState(false);
  // This ref keeps track of whether the autoDecreaseAlpha has finished decreasing
  const alphaOverrideEndRef = useRef(true);
  const [previousAlpha, setPreviousAlpha] = useState<number>(0.15);
  const [touchCount, setTouchCount] = useState<number>(0);

  const [showTutorial, setShowTutorial] = useState<string | undefined>(undefined);

  const handleGifPairChange = (value: string) => {
    const selectedPair = gifPairs.find(gifPair => gifPair.label === value);
    if (selectedPair) {
      navigate(`/${selectedPair.label}`);
    }
  };

  const handleAlphaRecording = (mappedAlpha: number) => {
    if (!autoDecreaseAlpha && alphaOverrideEndRef.current) {
       if (mappedAlpha) {
         setAlpha(mappedAlpha);
       }
    }
  }

  // This handles the mousedown event
  const handleCanvasMouseDown = () => {
    if (showTutorial === "tap") {
      setShowTutorial('tapHold');
    } else if (showTutorial) {
      setShowTutorial(undefined)
    }

    if(touchCount === 2) {
      setTouchCount(0);  // Reset touch count after showing the tutorial
    }

    // If autoDecreaseAlpha is active, exit early to avoid resetting alpha
    if (autoDecreaseAlpha) return;

    if (alpha < 1.0) setPreviousAlpha(alpha);  // store the current alpha
    setAlpha(1.0);
  };

  // This handles the mouseup event
  const handleCanvasMouseUp = () => {
    setTimeout(() => {
        setAutoDecreaseAlpha(true);
    }, 250);
    alphaOverrideEndRef.current = false;
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchCount(e.touches.length);

    if (e.touches.length === 2) {
      // setShowTapAndHoldTutorial(false);
      setAlpha(1.0);
    }
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    if (e.touches.length <= 1) {  // It will check if one of the fingers has been lifted off the screen
      setTimeout(() => {
        setAutoDecreaseAlpha(true);
      }, 250);
      alphaOverrideEndRef.current = false;
    }
  };

  useEffect(() => {
    const pair = gifPairs.find(gifPair => gifPair.label === gifPairLabel);
    if (pair) {
        setSelectedGifPair(pair);
    }
  }, [gifPairLabel]);

  useEffect(() => {
    isRecordingRef.current = isRecording; // Update the ref on every render
  }, [isRecording]);

  useEffect(() => {
    const isFirstVisit = !localStorage.getItem('visitedBefore');
    if (isFirstVisit) {
      setShowTutorial("tap")
      localStorage.setItem('visitedBefore', 'true');
    }
  }, []);

  useEffect(() => {
    const handleScroll = (event: any) => {
      const delta = event.deltaY;
      // Accumulate scroll delta. Adjust the values accordingly.
      accumulatedScrollDelta.current = Math.max(0, Math.min(1000, accumulatedScrollDelta.current + 0.1 * delta));
      // Convert accumulated delta to alpha
      const alphaFromScroll = 0.0 + (accumulatedScrollDelta.current / 1000) * 1.0;
      if (!isRecording) {
        setAlpha(alphaFromScroll);
      }
    };
    window.addEventListener('wheel', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('wheel', handleScroll);
    };
  }, [isRecording]);

  useEffect(() => {
    if (autoDecreaseAlpha && alpha > 0) {
        const decrementInterval = setInterval(() => {
            // Decrease the alpha by 0.05 (or any suitable amount) every 100ms
            setAlpha(prevAlpha => Math.max(previousAlpha, prevAlpha - 0.05));  // restore to previousAlpha
        }, 20);

        // Once alpha reaches previousAlpha or close to it, clear the interval and reset the auto-decrement flag
        if (alpha <= previousAlpha + 0.05) {
            clearInterval(decrementInterval);
            setAlpha(previousAlpha);
            setAutoDecreaseAlpha(false);
            alphaOverrideEndRef.current = true; // Indicate that the autoDecreaseAlpha has finished
        }

        return () => clearInterval(decrementInterval);
    }
  }, [alpha, autoDecreaseAlpha, previousAlpha]);


  return (
  <div className="App" style={{ height: '100vh', backgroundColor: '#f7f9fc' }}>
    {selectedGifPair && (
      <>
        <Container>
          <GifPlayer
            gifUrls={[selectedGifPair.backgroundGifUrl, selectedGifPair.gifUrl]}
            alphas={[1, alpha]}
            onMouseDown={handleCanvasMouseDown}
            onMouseUp={handleCanvasMouseUp}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}/>
        </Container>
        {showTutorial === 'tap' && (
          <TapTutorial>
            <TapInner>
              Tap
            </TapInner>
          </TapTutorial>
        )}
        {showTutorial === 'tapHold' && (
          <TapTutorial>
            <TapAndHoldInner>
              <DoubleTap />
            </TapAndHoldInner>
            <TapAndHoldLabel>
              Hold
            </TapAndHoldLabel>
          </TapTutorial>
        )}
        <Controls
          selectedGifPair={selectedGifPair}
          onChangeGifPair={handleGifPairChange}
          alpha={alpha}
          onAlphaChange={handleAlphaRecording}
          isRecording={isRecording}
          onIsRecordingChange={setIsRecording}
        />
      </>
    )}
    <FooterOverlay>
      2023 ⓒ DAO Llama Labs
      <a href="https://www.rmx.lol" target="_blank" rel="noopener noreferrer">
        <LogoWrapper>
          <RmxLogo />
        </LogoWrapper>
      </a>
      <a href="https://www.instagram.com/rmx_lol/" target="_blank" rel="noopener noreferrer">
      <LogoWrapper>
        <FaInstagram />
        </LogoWrapper>
      </a>
      🫶 <a href="https://synesthesia.pt/" target="_blank" rel="noopener noreferrer">
        synesthesia
      </a>

    </FooterOverlay>
  </div>
  );
 }


export default App;
