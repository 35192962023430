import React from 'react';
import styled from 'styled-components';
import { theme } from '../theme';


const RangeSlider = styled.input`
  width: 100%;
  -webkit-appearance: none;
  background: transparent;
  cursor: pointer;

  /* Track styles for all browsers */
  &::-webkit-slider-runnable-track {
    height: 7px;
    background: linear-gradient(to right, rgba(255,0,0,0.6), rgba(255,165,0,0.6), rgba(255,255,0,0.6), rgba(0,128,0,0.6), rgba(0,0,255,0.6), rgba(75,0,130,0.6), rgba(238,130,238,0.6));
    cursor: pointer;
    border-radius: 5px;
  }

  &::-moz-range-track {
    height: 7px;
    background: linear-gradient(to right, rgba(255,0,0,0.6), rgba(255,165,0,0.6), rgba(255,255,0,0.6), rgba(0,128,0,0.6), rgba(0,0,255,0.6), rgba(75,0,130,0.6), rgba(238,130,238,0.6));
    cursor: pointer;
  }

  &::-ms-track {
    height: 7px;
    background: linear-gradient(to right, rgba(255,0,0,0.6), rgba(255,165,0,0.6), rgba(255,255,0,0.6), rgba(0,128,0,0.6), rgba(0,0,255,0.6), rgba(75,0,130,0.6), rgba(238,130,238,0.6));
    cursor: pointer;
  }

  &::-webkit-slider-thumb,
  &::-moz-range-thumb,
  &::-ms-thumb {

  }

  /* Thumb styles for Webkit browsers */
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid ${theme.highlightColor};
    transform: translateY(-35%);
    cursor: pointer;
    transition: background-color 0.3s;
    opacity: 0.7;
  }

  /* Thumb styles for Firefox */
  &::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  /* Thumb styles for IE/Edge */
  &::-ms-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;

  }

  &::-webkit-slider-thumb {
    background-color: var(--thumbColor, ${theme.highlightColor});
  }

  &::-moz-range-thumb {
    background-color: var(--thumbColor, ${theme.highlightColor});
  }

  &::-ms-thumb {
    background-color: var(--thumbColor, ${theme.highlightColor});
  }

  &:hover::-webkit-slider-thumb,
  &:active::-webkit-slider-thumb {
    opacity: 1;
    transform: scale(1.2) translateY(-30%);
    transition: opacity 0.7s;
  }

  &:hover::-moz-range-thumb,
  &:active::-moz-range-thumb {
    opacity: 1;
    transform: scale(1.2) translateY(-30%);
    transition: opacity 0.7s;
  }

  &:hover::-ms-thumb,
  &:active::-ms-thumb {
    opacity: 1;
    transform: scale(1.2) translateY(-30%);
    transition: opacity 0.7s;
  }
`;

const RangeContainer = styled.div<{ enabled: boolean }>`
  display: flex;
  align-items: center;
  zIndex: 1;
  opacity: ${props => props.enabled ? 0.5 : 1};
`;

interface RangeInputProps {
  value: number;
  isEnabled: boolean;
  onValueChange: (value: number) => void;
}

const RangeInput: React.FC<RangeInputProps> = ({ value, isEnabled, onValueChange }) => {
  const sliderRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (sliderRef.current) {
      const colorArray = ["red", "orange", "yellow", "green", "blue", "indigo", "violet"].reverse();
      const index = Math.round(value * (colorArray.length - 1));
      const color = colorArray[index];
      sliderRef.current.style.setProperty("--thumbColor", color);
    }
  }, [value]);

  return (
    <RangeContainer enabled={isEnabled}>
      <RangeSlider
        ref={sliderRef}
        type="range"
        value={value}
        disabled={isEnabled}
        min="0"
        max="1"
        step="0.01"
        onChange={e => onValueChange(parseFloat(e.target.value))}
      />
    </RangeContainer>
  );
};

export default RangeInput
