import React, { useCallback, useEffect, useState, useRef } from 'react';
import { FaRegDotCircle, FaArchive, FaStopCircle, FaDiceFive } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';
import RangeInput from './RangeSlider'
import gifPairs from '../+assets/gifs.json';
import { theme } from '../theme'


const NUM_BINS = 20;

const heartbeat = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.6;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.6;
  }
`;

const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
  height: 60px;
  max-width: 400px;
  gap: 10px;
  background-color: rgba(${theme.secondaryColor}, 0.7);
  padding: 5px 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0,0,0,0.3);

  @media (max-width: 600px) {
    padding: 5px 10px; // Reducing the horizontal padding for mobile
    gap: 5px;         // Reducing the gap between elements for mobile
    max-width: calc(100% - 20px); // Making it full width with a margin of 10px on each side
  }
`;


const Select = styled.select`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid ${theme.borderColor};
  margin-right: 5px;
  background-color: rgba(0, 0, 0, 0.3);
  color: ${theme.secondaryColor};
  appearance: none;
  webkit-appearance: none;
  moz-appearance: none;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: rgba(29, 255, 93, 0.6);
  }
`;

const SelectContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledFaArchive = styled(FaArchive)`
  position: absolute;
  left: 25%;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: ${theme.secondaryColor};
`;

const StyledSelect = styled(Select)`
  width: 40px; // width of the FaArchive icon
  overflow: hidden;
  transition: width 0.3s ease-in-out; // smooth transition

  &:focus {
    width: 150px; // you can adjust this value to what you find suitable
  }
`;


const RandomButtonStyled = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${theme.secondaryColor};
  border-radius: 5px;
  transition: 0.2s;
  font-size: 30px;
  display: flex;

  &:hover {
    color: rgba(29, 255, 93, 0.6);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50px;
  justify-content: center;
  margin-right: 0.5em;
`;

const RecordButtonStyled = styled.button`
  background: none;
  display: flex;
  border: none;
  cursor: pointer;
  color: ${theme.errorColor};
  font-size: 30px;
  animation: ${heartbeat} 1s infinite
`;

const OverlayContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FrequencyContainer = styled.div`
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; // Align items in the center
  height: 50px;
  z-index: 2;
`;

const Bin = styled.div<{ height: number, backgroundColor: string, opacity: number }>`
  width: 15px;
  height: ${props => Math.abs(props.height)}%;  // Take the absolute value of the height
  margin: 0 1px;
  background-color: ${props => props.backgroundColor};
  opacity: ${props => props.opacity};
  transition: opacity 0.04s linear;
  transform: translateY(${props => props.height < 0 ? `${props.height / 2}%` : '0'});  // Adjust position based on height
`;


function getColorForBin(binIndex: number): string {
    const colorArray = ["red", "orange", "yellow", "green", "blue", "indigo", "violet"];
    return colorArray[binIndex % colorArray.length];
}

const processAudio = (analyser: AnalyserNode, dataArray: Uint8Array): [number, number[]] => {
  analyser.getByteFrequencyData(dataArray);
  let values = 0;
  for (let i = 0; i < dataArray.length; i++) {
    values += dataArray[i];
  }
  let average = values / dataArray.length;
  let mappedAlpha = 0.0 + (3.0 * average / 255) * 1.0;
  const currentBinAverages: number[] = [];

  for (let i = 0; i < dataArray.length; i += Math.ceil(dataArray.length / NUM_BINS)) {
    const binData = dataArray.slice(i, i + Math.ceil(dataArray.length / NUM_BINS));
    const binAvg = binData.reduce((acc, val) => acc + val, 0) / binData.length;
    currentBinAverages.push(binAvg);
  }

  return [mappedAlpha, currentBinAverages]
}


const Controls: React.FC<{
  selectedGifPair: any,
  onChangeGifPair: (value: string) => void,
  alpha: number,
  onAlphaChange: (value: number) => void,
  isRecording: boolean,
  onIsRecordingChange: (value: boolean) => void;
}> = ({
  selectedGifPair, onChangeGifPair, alpha, onAlphaChange, isRecording, onIsRecordingChange
}) => {
  const mediaStream = useRef<MediaStream | null>(null);
  const isRecordingRef = useRef(isRecording);
  const [binAverages, setBinAverages] = useState<number[]>(Array(NUM_BINS).fill(0));
  const selectRef = useRef<HTMLSelectElement | null>(null);

  useEffect(() => {
    const selectElement = selectRef.current;

    if (!selectElement) return; // Safeguard in case the ref is not yet attached

    const handleFocus = () => {
      // Dynamically set the width based on the widest option.
      let maxWidth = 40;
      for (let i = 0; i < selectElement.length; i++) {
        const optionWidth = selectElement.options[i].offsetWidth;
        if (optionWidth > maxWidth) maxWidth = optionWidth;
      }
      selectElement.style.width = `${maxWidth}px`;
    };

    const handleBlur = () => {
      selectElement.style.width = "40px"; // back to the icon's width
    };

    selectElement.addEventListener("focus", handleFocus);
    selectElement.addEventListener("blur", handleBlur);

    return () => {
      selectElement.removeEventListener("focus", handleFocus);
      selectElement.removeEventListener("blur", handleBlur);
    };
  }, []);

  const handleStartRecording = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.log("Your browser does not support the audio API");
      return;
    }

    try {
      mediaStream.current = await navigator.mediaDevices.getUserMedia({ audio: true })

      let audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
      let analyser = audioContext.createAnalyser();
      analyser.fftSize = 256;
      let dataArray = new Uint8Array(analyser.frequencyBinCount);
      let source = audioContext.createMediaStreamSource(mediaStream.current);
      source.connect(analyser);

      const update = () => {
        if (isRecordingRef.current) {
          let [mappedAlpha, currentBinAverages] = processAudio(analyser, dataArray)
          onAlphaChange(mappedAlpha);
          if (Math.max(...currentBinAverages)) {
            setBinAverages(currentBinAverages);
          }
        }
        setTimeout(update, 100); // Update 10 times every second
      };
      update();
      onIsRecordingChange(true);

    } catch (e) {
      console.error(e);
      onIsRecordingChange(false);
    }
  };

  const handleStopRecording = useCallback(() => {
     if (mediaStream.current && isRecordingRef.current) {
       mediaStream.current.getTracks().forEach(track => track.stop());
       onIsRecordingChange(false);
     }
  }, [isRecording]);

  useEffect(() => {
    isRecordingRef.current = isRecording; // Update the ref on every render
  }, [isRecording]);

  useEffect(() => {
    return () => {
      handleStopRecording();  // Close and cleanup any active streams
    };
  }, [selectedGifPair, handleStopRecording]);

  const handleRandomGifPair = () => {
    const publicGifPairs = gifPairs.filter(gifPair => "permission" in gifPair);
    const randomPair = publicGifPairs[Math.floor(Math.random() * publicGifPairs.length)];
    onChangeGifPair(randomPair.label); // Simulating an event object for the onChange handler
  };

  return (
   <ControlsContainer>
   <RandomButtonStyled onClick={handleRandomGifPair}>
      <FaDiceFive />
   </RandomButtonStyled>
   <SelectContainer>
      <StyledSelect
          ref={selectRef}
          value={selectedGifPair.label}
          onChange={e => {
            const selectedPair = gifPairs.find(pair => pair.label === e.target.value);
            if (selectedPair) {
              onChangeGifPair(selectedPair.label);
            }
          }}
      >
        {/* Display empty label for the selected option */}
        <option value={selectedGifPair.label} style={{ backgroundColor: '#333', color: 'white' }}></option>

         {gifPairs
             .filter(gifPair => "permission" in gifPair)
             .map((pair, idx) => (
               <option key={idx} value={pair.label} style={{ backgroundColor: '#333', color: 'white' }}>
                  {pair.label}
               </option>
         ))}
     </StyledSelect>
     <StyledFaArchive />
   </SelectContainer>


     <OverlayContainer>
       { isRecording && (
         <FrequencyBins binAverages={binAverages} />
       )}

       <RangeInput
         value={alpha}
         isEnabled={isRecording}
         onValueChange={onAlphaChange}
       />
     </OverlayContainer>

     {isRecording ? (
         <StopRecordButton onStopRecording={handleStopRecording} />
     ) : (
         <RecordButton onStartRecording={handleStartRecording} />
     )}
   </ControlsContainer>
 );
}


const StopRecordButton: React.FC<{ onStopRecording: () => void }> = ({ onStopRecording }) => {
   return (
       <ButtonContainer>
           <RecordButtonStyled onClick={onStopRecording}>
               <FaStopCircle />
           </RecordButtonStyled>
       </ButtonContainer>
   );
}


const RecordButton: React.FC<{ onStartRecording: () => void }> = ({ onStartRecording }) => {
   return (
       <ButtonContainer>
           <RecordButtonStyled onClick={onStartRecording} >
               <FaRegDotCircle />
           </RecordButtonStyled>
       </ButtonContainer>
   );
}


const FrequencyBins: React.FC<{ binAverages: number[] }> = ({ binAverages }) => {
   return (
     <FrequencyContainer>
        {binAverages.slice(0, binAverages.length / 2).map((avg, index) => (
          <Bin
            key={index}
            height={avg / 255 * 100}
            backgroundColor={getColorForBin(index)}
            opacity={0.3 + avg / 255}
          />
        ))}
     </FrequencyContainer>
   );
}

export default Controls;
