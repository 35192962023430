import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  FaArrowCircleLeft,
  FaArrowCircleRight,
  FaMixer,
  FaPauseCircle,
  FaPlayCircle,
  FaSync,
  FaUndoAlt
} from 'react-icons/fa';
import { Canvas, useWorkerParser, usePlayback, usePlayerState } from '@react-gifs/tools';
import { theme } from '../../theme'


export interface GifPlayerProps {
  gifUrls: string[];
  alphas: number[];
  onGifsLoaded?: () => void;
  style?: React.CSSProperties;
  onClick?: () => void;
  onMouseDown?: () => void;
  onMouseUp?: () => void;
  onTouchStart?: (e: React.TouchEvent) => void;
  onTouchEnd?: (e: React.TouchEvent) => void;
}

const CanvasContainer = styled.div`
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  background-color: black;
  cursor: pointer;
`;


const OverlayContainer = styled.div`
  position: fixed;       // Adjust to fixed position
  bottom: 5px;           // Set distance from bottom
  left: 50%;             // Center horizontally
  transform: translateX(-50%);  // Adjust for perfect centering
  width: 80%;
  max-width: 400px;
  z-index: 10;
  background-color: rgba(3, 3, 3, 0.1);
  border-radius: 20px;
  padding: 0;
  color: ${theme.secondaryColor};
  border: 0px solid ${theme.borderColor};
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {  // Typical breakpoint for tablets and below
    bottom: 25px;
  }
`;

const TimelineContainer = styled.div`
  width: 100%;
  margin-left: 35px;
`;

const TimelineWrapper = styled.div`
  position: relative;
  height: 20px;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
`;

const Timeline = styled.div`
  width: 100%;
  height: 7px;
  background-color: rgba(255, 255, 255, 0.8);  // white color
  z-index: 1;
  border-radius: 5px;
  margin: -3px 0;
`;

const Cursor = styled.div<{ position: number }>`
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: ${theme.highlightColor};
  border-radius: 50%;
  transform: translateY(-50%);
  left: ${({ position }) => (isNaN(position) ? '0%' : `${position}%`)};
  transition: left 0.2s;
  z-index: 2;

  &:hover,
  &:active {
    background-color: ${theme.errorColor};  // Use error color on hover
  }
`;

const ControlPanel = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  width: 150px;
  margin: 0 auto;

  @media (max-width: 768px) {  // Typical breakpoint for tablets and below
    grid-template-columns: auto auto auto;  // Set columns to only take as much space as they need
    justify-content: center;  // Center the columns in the container
  }
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${theme.secondaryColor};
  margin: 0 5px;
  transition: color 0.3s ease;
  font-size: 20px;  // Icon size

  @media (max-width: 768px) {  // Typical breakpoint for tablets and below
    font-size: 16px;  // Smaller size for mobile view
  }

  &:hover {
    color: ${theme.highlightColor};  // Use error color on hover
  }
`;

const GifPlayer: React.FC<GifPlayerProps> = ({
  gifUrls,
  alphas,
  onGifsLoaded,
  ...props }) => {

  if (gifUrls.length !== alphas.length) {
    throw new Error("The number of gifUrls should match the number of alpha values.");
  }

  const [state, update] = usePlayerState();
  const totalFrames = state.frames?.length || 0;
  const progressBarRef = useRef<HTMLDivElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [wasPlayingBeforeDrag, setWasPlayingBeforeDrag] = useState(false);
  const [isReversed, setIsReversed] = useState(false);
  const [loadedGifsCount, setLoadedGifsCount] = useState(0);
  const [playbackMode, setPlaybackMode] = useState<"bounce" | "loop">("bounce");
  const [loadedGifs, setLoadedGifs] = useState<boolean[]>(new Array(gifUrls.length).fill(false));

  usePlayback(state, () => {
    if (state.playing) {
      let newIndex = isReversed ? state.index - 1 : state.index + 1;

      // Handle bounce mode
      if (playbackMode === "bounce" && (newIndex < 0 || newIndex >= totalFrames)) {
          setIsReversed(!isReversed);  // Reverse direction
          newIndex = isReversed ? state.index + 1 : state.index - 1;  // Adjust the index after reversing
      }

      // Handle loop mode
      if (playbackMode === "loop" && (newIndex < 0 || newIndex >= totalFrames)) {
          newIndex = isReversed ? totalFrames - 1 : 0;  // Set the index based on the direction
      }

      update({ index: newIndex });
    }
  });

  const handlePlay = useCallback(() => {
    update({ playing: true });
  }, [update]);

  const handlePause = useCallback(() => {
    update({ playing: false });
  }, [update]);

  const handleReset = useCallback(() => {
    update({ index: isReversed ? totalFrames - 1 : 0, playing: false });
    setTimeout(() => {
      update({ playing: true });
    }, 20);
  }, [update, isReversed, totalFrames]);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isDragging) {
      handleFrameChange(e);
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (state.playing) {
      setWasPlayingBeforeDrag(true);
      update({ playing: false });  // pause playback
    }
    setIsDragging(true);
    handleFrameChange(e);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    if (wasPlayingBeforeDrag) {
      // update({ playing: true });  // resume playback
      setWasPlayingBeforeDrag(false);
    }
  };

  const handleFrameChange = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (progressBarRef.current) {
      const rect = progressBarRef.current.getBoundingClientRect();
      const newProgress = (e.clientX - rect.left) / rect.width;
      const newIndex = Math.round(newProgress * totalFrames);
      update({ index: newIndex });
    }
  };

  const cursorPosition = totalFrames > 0 ? (state.index / totalFrames) * 100 : 0;

  const handleGifLoaded = useCallback((gifUrl: string, parsedGifData: any) => {
    console.log(gifUrl, loadedGifsCount)
    setLoadedGifsCount(prevCount => prevCount + 1);
    const index = gifUrls.indexOf(gifUrl);
    setLoadedGifs(prev => {
        const newLoadedGifs = [...prev];
        newLoadedGifs[index] = true;
        return newLoadedGifs;
    });
    if (!state.frames.length) {
        update(parsedGifData);
        update({loaded:true, playing:true});
        console.log('handled loading', gifUrl);
    }
    console.log('after loaded', loadedGifs)
  }, [setLoadedGifsCount]);

  const handleContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      let newIndex;

      switch (e.key) {
          case "ArrowUp":
              // Implement logic to move one frame forward
              newIndex = state.index + 1;
              if (newIndex < totalFrames) {
                  update({ index: newIndex });
              }
              break;

          case "ArrowDown":
              // Implement logic to move one frame backward
              newIndex = state.index - 1;
              if (newIndex >= 0) {
                  update({ index: newIndex });
              }
              break;

          case " ":
          case "Spacebar": // Older browsers may use "Spacebar" instead of " "
              // Toggle play/pause
              update({ playing: !state.playing });
              break;

          default:
              break;
      }
  };
  document.addEventListener("keydown", handleKeyDown);

  // Cleanup the event listener when the component is unmounted
  return () => {
        document.removeEventListener("keydown", handleKeyDown);
    };
  }, [state, update, totalFrames]);


  useEffect(() => {
    if (loadedGifsCount === gifUrls.length && onGifsLoaded) {
      onGifsLoaded()
    }
  }, [loadedGifsCount])

  return (
    <div {...props}>
        {gifUrls.map((url, index) => {
            // Only render the GifCanvas if the current GIF or any previous GIFs are loaded
            if (url && (index === 0 || loadedGifs[index - 1])) {
                return (
                    <GifCanvas
                        key={index}
                        gifUrl={url}
                        alpha={alphas[index]}
                        playerState={state}
                        onGifLoaded={handleGifLoaded}
                    />
                );
            }
            return null;
        })}
        <OverlayContainer
            onClick={handleContainerClick}
            onMouseDown={props.onMouseDown}
            onMouseUp={props.onMouseUp}
        >
            <TimelineContainer>
                <ControlPanel>
                    <IconButton style={{transform: 'scale(0.8)'}} onClick={handleReset}><FaUndoAlt /></IconButton>
                    {state.playing ?
                        <IconButton style={{fontSize: '36px'}} onClick={handlePause}><FaPauseCircle /></IconButton> :
                        <IconButton style={{fontSize: '36px'}} onClick={handlePlay}><FaPlayCircle /></IconButton>
                    }
                    <IconButton onClick={() => setIsReversed(!isReversed)}>
                        {isReversed ? <FaArrowCircleRight /> : <FaArrowCircleLeft />}
                    </IconButton>
            </ControlPanel>
            <TimelineWrapper
              ref={progressBarRef}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
            >
                <Timeline />
                <Cursor position={cursorPosition} />
            </TimelineWrapper>
            </TimelineContainer>
            <IconButton
                style={{transform: 'scale(0.7) translateY(-10px)', alignSelf: 'end'}}
                onClick={() => {
                  if (playbackMode === "bounce") {
                      setPlaybackMode("loop");
                  } else {
                      setPlaybackMode("bounce");
                  }
              }}
            >
                {playbackMode === "bounce" ? <FaSync /> : <FaMixer />}
            </IconButton>
        </OverlayContainer>
    </div>
  );
};

interface GifCanvasProps {
  gifUrl: string;
  alpha: number;
  playerState: any; // Replace `any` with the actual state type if known.
  onGifLoaded: (gifUrl: string, parsedGifData: any) => void;
}

const GifCanvas: React.FC<GifCanvasProps> = ({ gifUrl, alpha, playerState, onGifLoaded }) => {
    const [gifData, setGifData] = useState<any>(); // Store parsedGifData in local state.
    useWorkerParser(gifUrl, parsedGifData => {
      if ('error' in parsedGifData) {
        console.error("Error parsing GIF:", gifUrl, parsedGifData);
        return;
      }
      setGifData(parsedGifData);
      onGifLoaded(gifUrl, parsedGifData); // Call the onGifLoaded callback when a GIF has been loaded
      console.log('loaded worker', gifUrl)
    });

    const canvasProps = {
        ...playerState,
        ...gifData
    };

    return (
        <CanvasContainer style={{ opacity: alpha }}>
            <Canvas {...canvasProps} style={{ width: "100%", height: "100%", objectFit: "contain"}} />
        </CanvasContainer>
    );
};

export default GifPlayer;
